import { render, staticRenderFns } from "./documents.vue?vue&type=template&id=99aa1012&scoped=true&"
import script from "./documents.vue?vue&type=script&lang=js&"
export * from "./documents.vue?vue&type=script&lang=js&"
import style1 from "./documents.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99aa1012",
  null
  
)

export default component.exports